const axios = require('axios');


const emailsService = {

    getEmail(usuario_id) {
        return axios.get(`${process.env.VUE_APP_API_URL}api/emails?usuario_id=${usuario_id}`,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },

    postEmail(usuario_id, email) {
        return axios.post(`${process.env.VUE_APP_API_URL}api/emails?usuario_id=${usuario_id}`, email,
        {
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },
}

module.exports = emailsService;