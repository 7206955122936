<template>
  <div>

    <CRow v-if="isLoading">
      <CCol sm="12">
        <p>Cargando...</p>
      </CCol>
    </CRow>

    <div v-if="!isLoading">
      <CRow >
        <CCol sm="12">
          <h3>Correos electrónicos</h3>
          <br>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="8">
          <CCard>
            <CForm v-bind:wasValidated="this.validationError">
              <CCardBody>
                <CToaster v-if="errores.length" :autohide="3000">
                  <template v-for="error in errores">
                    <CToast
                      color="danger"
                      :key="'error' + error"
                      :show="true"
                      header="Error de validación"
                    >
                      {{error}}
                    </CToast>
                  </template>
                </CToaster>
                <CTextarea v-model="email.text_delivery"
                  description="Este texto informará al cliente sobre los costos del servicios de delivery puerta a puerta."
                  autocomplete="text_delivery"
                  label="Servicio puerta a puerta"
                  placeholder="Escribé algo..."
                  required
                />
                <CTextarea v-model="email.text_descuento"
                  description="Este texto informará al cliente sobre los descuentos por transferencia o efectivo."
                  autocomplete="text_descuento"
                  label="Descuentos por efectivo/transferencia"
                  placeholder="Escribé algo..."
                  required
                />
                <CTextarea v-model="email.text_horarios"
                  description="Este texto informará al cliente los horarios de atención disponibles."
                  autocomplete="text_horarios"
                  label="Horarios de atención"
                  placeholder="Escribé algo..."
                  required
                />
              </CCardBody>
              <CCardFooter>
                <CButton v-on:click.prevent="triggerUpdateEmail()" type="submit" color="success">Guardar cambios</CButton>
              </CCardFooter>
            </CForm>
          </CCard>
        </CCol>
      </CRow>
      <br>
      <CRow>
        <CCol sm="12">
          <h3>Actualización masiva de precios</h3>
          <br>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="8">
          <CCard>
            <CForm v-bind:wasValidated="this.validationError">
              <CCardBody>
                <CToaster v-if="errores.length" :autohide="3000">
                  <template v-for="error in errores">
                    <CToast
                      color="danger"
                      :key="'error' + error"
                      :show="true"
                      header="Error de validación"
                    >
                      {{error}}
                    </CToast>
                  </template>
                </CToaster>
                <CInput v-model="incrementPercent"
                  type="number"
                  description="Porcentaje de aumento a aplicar a las reparaciones"
                  autocomplete="percent"
                  label="Porcentaje de aumento"
                  placeholder=""
                  min="1"
                  max="100"
                  style="width: 250px;"
                  required
                />
              </CCardBody>
              <CCardFooter>
                <CButton v-on:click.prevent="triggerIncrementPrices()" type="submit" color="success">Actualizar precios</CButton>
              </CCardFooter>
            </CForm>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="8">
          <CCard>
            <CForm v-bind:wasValidated="this.validationError">
              <CCardBody>
                <CToaster v-if="errores.length" :autohide="3000">
                  <template v-for="error in errores">
                    <CToast
                      color="danger"
                      :key="'error' + error"
                      :show="true"
                      header="Error de validación"
                    >
                      {{error}}
                    </CToast>
                  </template>
                </CToaster>
                <CInput v-model="reducePercent"
                  type="number"
                  description="Porcentaje de descuento a aplicar a las reparaciones"
                  autocomplete="percent"
                  label="Porcentaje de descuento"
                  placeholder=""
                  min="1"
                  max="100"
                  style="width: 250px;"
                  required
                />
              </CCardBody>
              <CCardFooter>
                <CButton v-on:click.prevent="triggerReducePrices()" type="submit" color="success">Actualizar precios</CButton>
              </CCardFooter>
            </CForm>
          </CCard>
        </CCol>
      </CRow>
    </div>
    
  </div>
</template>

<script>
import emailsService from '../../services/emails.service';
import reparacionesService from '../../services/reparaciones.service';
const Swal = require('sweetalert2');

export default {
  name: 'Config',
  data() {
    return {
      email: {},
      incrementPercent: false,
      reducePercent: false,
      isLoading: true,
      errores: [],
      validationError: false,
    }
  },
  created() {
    this.getEmails();
  },
  updated() {

  },
  methods: {
    getEmails: function() {
      emailsService.getEmail(this.$store.state.user.data.id).then((response) => {
        // handle success
        this.email = response.data.email;
        this.isLoading = false;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
    checkEmailForm: function () {
      if (this.email.text_delivery && this.email.text_descuento && this.email.text_horarios) {
        this.errores = [];
        this.validationError = false;
        return true;
      }

      this.errores = [];
      this.validationError = true;
      if (!this.email.text_delivery || !this.email.text_descuento || !this.email.text_horarios) {
        this.errores.push('Los textos son obligatorios.');
      }
    },
    checkIncrementPricesForm: function () {
      if (this.incrementPercent) {
        this.errores = [];
        this.validationError = false;
        return true;
      }

      if (!this.incrementPercent) {
        this.errores = [];
        this.validationError = true;
        this.errores.push('Debés ingresar un porcentaje de aumento.');
      }
    },
    checkReducePricesForm: function () {
      if (this.reducePercent) {
        this.errores = [];
        this.validationError = false;
        return true;
      }

      if (!this.reducePercent) {
        this.errores = [];
        this.validationError = true;
        this.errores.push('Debés ingresar un porcentaje de reducción.');
      }
    },
    triggerUpdateEmail: function() {
      if(!this.checkEmailForm()){
        return;
      }

      const email = {
        text_delivery: this.email.text_delivery,
        text_descuento: this.email.text_descuento,
        text_horarios: this.email.text_horarios,
      }

      emailsService.postEmail(this.$store.state.user.data.id, email).then((response) => {
        // handle success
        Swal.fire({
          title: '¡Listo!',
          text: 'Se han guardado los cambios.',
          type: 'success',
        })

        this.email = response.data;
      }).catch((error) => {
        // handle error
        console.log(error);
        Swal.fire({
          title: 'Error',
          text: 'Hubo un problema al guardar los cambios.',
          type: 'error',
        })
      });
    },
    triggerIncrementPrices: function(){

      if(!this.checkIncrementPricesForm()){
        return;
      }

      Swal.fire({
        title: '¿Estás seguro?',
        text: "Esta acción actualizará los precios de todas las reparaciones.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, actualizar'
      }).then((result) => {

        const data = {
          percent: this.incrementPercent,
        }

        if (result.isConfirmed) {
          reparacionesService.incrementReparacionesPrice(this.$store.state.user.data.id, data).then(() => {
            // handle success
            Swal.fire({
              title: '¡Listo!',
              text: 'Se han actualizado los precios.',
              type: 'success',
            })
          }).catch((error) => {
            // handle error
            console.log(error);
            Swal.fire({
              title: 'Error',
              text: 'Hubo un problema al actualizar los precios.',
              type: 'error',
            })
          });
        }
      })
    },
    triggerReducePrices: function(){

      if(!this.checkReducePricesForm()){
        return;
      }

      Swal.fire({
        title: '¿Estás seguro?',
        text: "Esta acción actualizará los precios de todas las reparaciones.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, actualizar'
      }).then((result) => {

        const data = {
          percent: this.reducePercent,
        }

        if (result.isConfirmed) {
          reparacionesService.reduceReparacionesPrice(this.$store.state.user.data.id, data).then(() => {
            // handle success
            Swal.fire({
              title: '¡Listo!',
              text: 'Se han actualizado los precios.',
              type: 'success',
            })
          }).catch((error) => {
            // handle error
            console.log(error);
            Swal.fire({
              title: 'Error',
              text: 'Hubo un problema al actualizar los precios.',
              type: 'error',
            })
          });
        }
      })
    }
  }
}
</script>
